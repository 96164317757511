import axios from "axios";
import cookieCutter from "cookie-cutter";
var randomstring = require("randomstring");
import Cookies from "js-cookie";
//plan services for form which append on learn more

async function _planServices(PostData = {}) {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  try {
    const response = await axios.post(process.env.planServices, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//register api that use when user register path where it used /page/[page]/signup.js
async function signUp(PostData = {}) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  };
  try {
    const response = await axios.post(process.env.sinUpApi, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for login user.  path where it used /page/[page]/login.js
async function loginUser(PostData = {}) {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.loginApi, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for dna reports view and download//

async function dnaReportsapi({ slug }) {
  const timestamp = Date.now();
  const headers = {
    "Content-Type": "application/json",
    // Authorization: localStorage.getItem("Token"),
  };

  try {
    const response = await axios.get(`${process.env.getDnareportsApi}?reportSlug=${slug}&reports=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function downloadAllReports({ barcode }) {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {
    const response = await axios.get(`${process.env.downloadAllReports}?barcode=${barcode}&download=${timestamp}`, { headers });
    return response
  }
  catch (error) {
    return error.response;
  }
}

//api for logout user  path where it used /page/_app.js
async function logoutApi(PostData) {
  const headers = {
    Authorization: PostData,
  };
  const data = "";
  try {
    const response = await axios.post(process.env.logoutApi, data, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for invite link--------

async function invitationLink(action = "get", PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("Token"),
  };
  try {
    let response = "";
    if (action === "post") {
      response = await axios.post(process.env.invitationLink, PostData, {
        headers,
      });
    } else {
      response = await axios.get(`${process.env.invitationLink}/?leadid=${timestamp}`, { headers });
    }
    return response;
  } catch (error) {
    return error.response;
  }
}
//lickclicked api

async function clickedapi(PostData = {}) {
  const headers = { "Content-Type": "application/json" };
  try {
    const response = await axios.post(process.env.clickedapi, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
// referring lookup

async function affiliate_lookup(PostData = {}) {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.affiliate_lookup, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}


// upsell order//
async function createUpsellOrder(PostData = {}) {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.createUpsellOrder, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


async function CheckUserEmail(PostData) {
  const headers = { "Content-Type": "application/json" };
  try {
    const response = await axios.post(
      process.env.Check_email_newuser,
      PostData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for add product to cart  path where it used /page/product/[name]/[id].js
async function addToCart(PostData = {}) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
  };
  let cookie = "";
  if (
    localStorage.getItem("Token") === "" ||
    localStorage.getItem("Token") === undefined ||
    localStorage.getItem("Token") === null
  ) {
    if (Cookies.get("sessionkey")?.length !== 16) {
      (cookie = randomstring.generate(16)),
        { domain: `.${location.hostname}.net` };
      Cookies.set("sessionkey", cookie);
    }
  }
  const PostDataa = {
    cookie_id: cookie || Cookies.get("sessionkey"),
    product_id: +PostData.product_id,
    variant_id: PostData?.variant_id ? +PostData.variant_id : null,
    quantity: +PostData.quantity,
    is_autoship: PostData?.is_autoship,

  };
  try {
    const response = await axios.post(
      process.env.addToCart,
      PostData?.datas ? PostData.datas : PostDataa,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getBanners(PostData) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getBanners}?slug=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//apui for getdashboardpopup//
async function getdashboardpopup() {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getdashboardpopup}`, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
// homepage content api
async function getHomepageContent(PostData) {
  const timestamp = Date.now();
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getHomepageContent}?slug=${PostData}&homepage=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//saveCustomerReview api
async function saveCustomerReview(PostData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return axios
    .post(`${process.env.saveCustomerReview}`, PostData, { headers })
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

//api for display cart data  path where it used /page/cart/viewcart.js
async function getAllCartProduct(PostData) {
  const LessPrice = Cookies?.get('apidiscount') ? Cookies?.get('apidiscount') : "";
  const Discount_Kit_DATA = Cookies.get("kitProduct") ? Cookies.get("kitProduct") : "";
  // const get_dna_paid = localStorage.getItem("dna_product_paid") ? localStorage.getItem("dna_product_paid") : "";
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
  };

  try {
    let url = `${process.env.getAllCartProduct}/?cookie_id=${cookieCutter.get("sessionkey")}&abc=${timestamp}`;
    if (LessPrice) {
      url += '&is_discounted_product=True';
    }
    if (PostData?.refferalCode && Discount_Kit_DATA) {
      url += `&refferalCode=${PostData?.refferalCode}&applyDiscount=True`
    }
    else if (PostData?.refferalCode) {
      url += `&refferalCode=${PostData?.refferalCode}`
    }
    else if (Discount_Kit_DATA) {
      url += '&applyDiscount=True';

    }

    const response = await axios.get(url, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function excludeCustomerApi(public_user_id) {
  const timestamp = Date.now();
  const headers = {
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(`${process.env.excludeCustomerApi}?public_user_id=${public_user_id}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for update qty in cart  path where it used /page/cart/viewcart.js
async function updateProductQty(PostData = {}) {
  const headers = {
    Authorization: PostData,
  };

  try {
    const response = await axios.get(process.env.getAllCartProduct, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for free product

async function freeKitProduct(postData) {
  const timestamp = Date.now();
  const headers = { Authorization: localStorage.getItem("Token") };
  try {
    const response = await axios.get(`${process.env.freeKitProduct}/?freekit=${timestamp}&category=${postData?.category ? postData?.category : ""}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for delete product from cart, path where it used /page/cart/viewcart.js
async function deleteProductFromAddToCart(PostData = {}) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
  };
  const PostDataa = {
    cookie_id: cookieCutter.get("sessionkey"),
    product_id: PostData.product_id,
    id: PostData?.id,
    is_autoship: PostData?.is_autoship,

    variant_id: PostData?.variant_id ? PostData.variant_id : null,
  };

  try {
    const response = await axios.post(
      process.env.deleteProductFromAddToCart,
      PostDataa,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for get list of address of login user , path where it used /page/checkout/addressList.js

async function manageAddress(PostData) {
  const timestamp = Date.now();
  const headers = {
    Authorization: PostData,
  };

  try {
    const response = await axios.get(`${process.env.manageAddress}/?address=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for save user address path where it used /page/checkout/address.js
async function saveAddress(PostData = {}) {
  const headers = {
    Authorization: PostData.token,
  };
  const PostDataa = { data: PostData.data, operation: PostData.address_type };

  try {
    const response = await axios.post(process.env.manageAddress, PostDataa, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for menu option on header
async function MenuSectionOption() {
  try {
    const response = await axios.get(process.env.menuSection);
    return response;
  } catch (err) {
    return err.response;
  }
}

// api for delete address ,path where it used /page/checkout/addressList.js

// api for get profile related data like persional,address list,order list, path where it used /page/user/profile.js
async function getProfilePageData(PostData) {
  const timestamp = Date.now();
  const headers = {
    Authorization: PostData,
    Authorization: localStorage.getItem("Token"),
  };

  try {
    const response = await axios.get(`${process.env.getProfilePageData}/?xyzz=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// api for change user login password path where it used /page/user/profile.js
async function changeUserPassword(PostData = {}) {
  const headers = {
    Authorization: PostData.token,
  };
  const PostDataa = {
    old_password: PostData.old_password,
    new_password: PostData.new_password,
  };

  try {
    const response = await axios.post(
      process.env.changeUserPassword,
      PostDataa,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for add product to wishlist path where it used /page/product/[name]/[id].js

async function addToWishlist(PostData = {}) {
  const headers = {
    Authorization: `${PostData.token}`,
  };
  const PostDataa = {
    product_id: +PostData.product_id,
    variant_id: PostData?.variant_id ? +PostData.variant_id : null,
    quantity: +PostData.quantity,
  };

  try {
    const response = await axios.post(process.env.addToWishlist, PostDataa, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for show product listing in wishlist path where it used /page/wishlist/wishlist.js
async function getAllWishListProduct(PostData) {
  const headers = {
    Authorization: PostData,
  };

  try {
    const response = await axios.get(process.env.getAllWishListProduct, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for delete product from wish list path where it used /page/wishlist/wishlist.js
async function deleteProductFromwishlist(PostData = {}) {
  const headers = {
    Authorization: PostData.token,
  };
  const PostDataa = {
    product_id: +PostData.product_id,
    variant_id: +PostData?.variant_id ? +PostData?.variant_id : null,
  };

  try {
    const response = await axios.post(
      process.env.deleteProductFromwishlist,
      PostDataa,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getAllProduct(PostData) {
  const timestamp = Date.now();
  const Discount_Kit_DATA = Cookies.get("kitProduct") ? Cookies.get("kitProduct") : "";
  const headers = {
    Accept: "application/json",
    Authorization: localStorage.getItem("Token"),
  };

  try {
    let url = `${process.env.getAllProduct}?slug=${PostData?.slug}&category=${PostData?.category ? PostData?.category : ""}&productdata=${timestamp}`
    if (PostData?.refferalCode && Discount_Kit_DATA) {
      url += `&refferalCode=${PostData?.refferalCode}&applyDiscount=True`
    }

    else if (PostData?.refferalCode) {
      url += `&refferalCode=${PostData?.refferalCode}`
    }
    else if (Discount_Kit_DATA) {
      url += '&applyDiscount=True'
    };

    const response = await axios.get(url, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// filter product
async function filterProduct(PostData, serchdata) {
  const headers = {
    Accept: "application/json",
    Authorization: localStorage.getItem("Token"),
  };

  try {
    const response = await axios.get(`${process.env.getAllProduct}?slug=${PostData}&search=${serchdata}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getProductByCategories(PostData) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${process.env.getProductByCategories}?category_id=${PostData}&search=`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getProductByProductid(PostData) {
  const headers = {
    Accept: "application/json",
    Authorization: localStorage?.getItem("Token")
      ? localStorage?.getItem("Token")
      : "",
  };

  try {
    const response = await axios.get(
      `${process.env.getProductByProductid}?product_id=${PostData}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getProductByVariantid(PostData) {
  const headers = {
    Accept: "application/json",
    Authorization: localStorage.getItem("Token"),
  };

  try {
    const response = await axios.get(
      `${process.env.getProductByVariantid}?variant_id=${PostData}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// Create order
async function createOrder(PostData = {}, token) {
  const headers = {
    Authorization: token,
  };

  try {
    const response = await axios.post(`${process.env.createOrder}`, PostData, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}

// varify copan
async function verifycoupon(PostData = {}, token) {
  const headers = {
    Authorization: token,
  };

  try {
    const response = await axios.post(process.env.verifycoupon, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
// get all category
async function getAllCategory(PostData) {
  const headers = {
    Accept: "application/json",
    // 'Authorization': localStorage?.getItem('Token') ? localStorage.getItem('Token') : ''
  };

  try {
    const response = await axios.get(
      `${process.env.getAllCategory}?slug=${PostData}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// update cart quantity

async function updateCart(PostData = {}) {
  const headers = {
    Authorization: `${PostData.token}`,
  };

  const PostDataa = {
    product_id: +PostData.product_id,
    variant_id: PostData?.variant_id ? +PostData.variant_id : null,
    quantity: +PostData.quantity,
  };
  try {
    const response = await axios.post(
      process.env.updateCart,
      PostData?.datas ? PostData.datas : PostDataa,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for get user getUserOrder list

async function getUserOrder(PostData, pagenumber) {
  const timestamp = Date.now();
  const headers = {
    Authorization: PostData,
  };

  try {
    const response = await axios.get(`${process.env.getUserOrder}?page=${pagenumber}&userorder=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for get PQV and GV of customer

async function getOrderPQVandGV(PostData) {
  const headers = {
    Authorization: PostData,
  };
  try {
    const response = await axios.get(process.env.getOrderPQVandGV, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for get user address details

async function getAddressDetails(PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${PostData.token}`,
  };

  try {
    const response = await axios.get(`${process.env.getAddressDetails}?address_id=${PostData.address_id}&details=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// get specific oder details
async function GetOrderDetail(PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${PostData.token}`,
  };

  try {
    const response = await axios.get(`${process.env.GetOrderDetail}?order_id=${PostData.order_id}&autoship_order_id=${PostData?.autoship_order_id}&orderdetail=${timestamp}`, { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete address

async function deleteAddress(PostData, token) {
  const headers = {
    Authorization: token,
  };

  try {
    const response = await axios.post(process.env.deleteAddress, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// re-pace same order that show in orderlist

async function reorderProducts(PostData = {}) {
  const headers = {
    Authorization: `${PostData.token}`,
  };

  const PostDataa = {
    order_id: +PostData?.order_id,
    cookie_id: cookieCutter.get("sessionkey"),
  };
  try {
    const response = await axios.post(process.env.reorderProducts, PostDataa, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//get addresslist

async function getAddressList(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getAddresslist}/?addresslist=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//upcoming orders API

async function upComingOrders(PostData) {
  const timestamp = Date.now();
  const headers = {
    Authorization: PostData,
  };

  try {
    const response = await axios.get(`${process.env.upComingOrders}/?upcomingorders=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for get user getUserAutoShipOrder list

async function autoshipOrderHistory(PostData, pagenumber) {
  const timestamp = Date.now();
  const headers = {
    Authorization: PostData,
  };

  try {
    const response = await axios.get(`${process.env.autoshipOrderHistory}?page=${pagenumber}&sec=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// get specific autoshipoder details
async function autoshipOrderById(PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${PostData.token}`,
  };
  try {
    const response = await axios.get(`${process.env.autoshipOrderById}?order_id=${PostData.order_id}&abc=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// update Autoship

async function autoshipUpdate(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  // const PostDataa = { "product_id": +(PostData.product_id),"variant_id": PostData?.variant_id ? +(PostData.variant_id):null, "quantity": +(PostData.quantity) }
  try {
    const response = await axios.post(process.env.autoshipUpdate, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete Autoship
async function autoshipDelete(PostData, token) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
  };

  try {
    const response = await axios.post(process.env.autoshipDelete, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete autoshipSkip
async function autoshipSkip(PostData, token) {
  const headers = {
    Authorization: token,
  };

  try {
    const response = await axios.post(process.env.autoshipSkip, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for Update user address path where it used /page/checkout/address.js
async function updateAddress(PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: PostData.token,
  };
  const PostDataa = { data: PostData.data };

  try {
    const response = await axios.post(
      `${process.env.updateAddress}/?ref=${timestamp}`,
      PostDataa,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// get notification details

async function getUserNotifications(PostData) {
  const headers = {
    Authorization: `${PostData}`,
  };

  try {
    const response = await axios.get(`${process.env.getUserNotifications}`, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete autoship order product

async function autoshipProductDelete(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.post(
      `${process.env.autoshipProductDelete}`,
      PostData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// update Autoship

async function autoshipProductUpateproduct(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  // const PostDataa = { "product_id": +(PostData.product_id),"variant_id": PostData?.variant_id ? +(PostData.variant_id):null, "quantity": +(PostData.quantity) }
  try {
    const response = await axios.post(
      process.env.autoshipProductUpateproduct,
      PostData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// update Autoship address

async function addressUpdate(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.post(process.env.addressUpdate, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
// edit smartship data
async function EditSmartshipData(PostData = {}) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("Token"),
  };
  try {
    const response = await axios.post(process.env.EditSmartshipData, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// reset password
async function resetPassword(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.post(process.env.resetPassword, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//reset confirm password
async function passwordResetConfirm(PostData = {}) {
  try {
    const response = await axios.post(
      process.env.passwordResetConfirm,
      PostData
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// Cancel Order
async function cancelOrder(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.post(process.env.cancelOrder, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// Refund Order
async function refundOrder(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.post(process.env.refundOrder, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// Api for get Bundle product and use in order refund section
async function getBundleProduct(PostData, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(
      `${process.env.getBundleProduct}/?product_id=${PostData}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
// Api for get downline user an d used in Dashboard

async function getDownlineUsers(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getDownlineUsers}/?downlineuser=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getPaginationUsers(token, pageNumber, pageSize) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };
  try {
    const response = await axios.get(`${process.env.getPaginationUsers}?page=${pageNumber}&pageSize=${pageSize}&paginate=${timestamp}`, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api to filter the downline users by date//
async function getPaginationUsersByDate(token) {
  const headers = {
    Accept: "application/json",
    Authorization: `${token?.token}`,
  };

  try {
    const response = await axios.post(`${process.env.getPaginationUsers}?page=${token?.page ? token?.page : "1"}&pageSize=${token?.pageSize}`, token, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}


// redeemKaireCash Order
async function redeemKaireCash(PostData, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(
      `${process.env.redeemKaireCash}/?order_total=${PostData}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundHistory Order
async function getRefundHistory(PostData, token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getRefundHistory}/?order_id=${PostData}&history=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getCommissionReport
async function getCommissionReport(payload, token, range) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getCommissionReport}?page=${range}&value=${payload?.filter_by_date}&from_date=${payload?.from_date ? payload.from_date : ""}&till_date=${payload?.til_date ? payload.til_date : ""}&search_value=${payload?.order_id ? payload?.order_id : ""}&status_filter=${payload?.status ? payload?.status : ""}&filter=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundHistory Order
async function getCommissionsFilter(PostData = {}, token) {
  const headers = { Authorization: `${token}` };

  try {
    const response = await axios.get(`${process.env.getCommissionsFilter}?value=${PostData?.filter_by_date}&from_date=${PostData?.from_date ? PostData.from_date : ""}&till_date=${PostData?.til_date ? PostData.til_date : ""
      }&search_on=order_id&search_value=${PostData?.order_id ? PostData?.order_id : ""}&status_filter=${PostData?.status ? PostData?.status : ""}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundHistory Order
async function getRefundReport(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getRefundReport}/?refundreport=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundReportFilter Order
async function getRefundReportFilter(PostData = {}, token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getRefundReportFilter}?date_filter=${PostData?.filter_by_date}&from_date=${PostData?.from_date ? PostData.from_date : ""}&till_date=${PostData?.til_date ? PostData.til_date : ""
      }&search_on=order_id&order_id=${PostData?.order_id}&refund=${timestamp}`, { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundHistory Order
async function getMyProfileDetails(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getMyProfileDetails}/?xyz=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getDashboardCommissions Order
async function getDashboardCommissions(range, token, PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getDashboardCommissions}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ""}&till_date=${PostData?.til_date ? PostData.til_date : ""}&search_on=order_id&order_id=${PostData?.order_id}&dashcommission=${timestamp}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// GetCommissionsApproved Order
async function GetCommissionsApproved(token, range, PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.GetCommissionsApproved}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ""
      }&till_date=${PostData?.til_date ? PostData.til_date : ""}&search_value=${PostData?.order_id ? PostData?.order_id : ""}&approved=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// LoginCheck Order
async function LoginCheck(token) {
  const timestamp = Date.now();
  const headers = { Authorization: token };

  try {
    const response = await axios.get(`${process.env.LoginCheck}/?logincheck=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//support desk apis
async function supportDeskPost(PostData = {}, method, section) {
  const headers = {
    Accept: "application/json",
    Authorization: localStorage.getItem("Token"), //`${ token }`
  };
  const formUrl = process.env.supportDesk;
  const chatUrl = process.env.supportDeskReply + "/" + PostData?.id;
  let postDataValue = {};
  let url = "";
  if (section === "save") {
    postDataValue = { data: PostData };
    url = formUrl;
  } else if (section === "tableData") {
    postDataValue = {};
    url = formUrl;
  } else if (section === "getChat") {
    postDataValue = {};
    url = chatUrl;
  } else {
    postDataValue = { data: PostData };
    url = chatUrl;
  }

  try {
    const response = await axios({
      method: method,
      url: url,
      ...postDataValue,
      headers: headers,
    });

    return response;
  } catch (error) {
    return error.response;
  }
}

// LoginCheck Order
async function LoginCheckByurl(token) {
  const headers = {
    Authorization: `${token}`, //localStorage.getItem('Token') //`${ token }`
  };

  try {
    const response = await axios.get(`${process.env.LoginCheck}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// get specific oder details
async function getOrderDetailForRefund(PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${PostData.token}`,
  };

  try {
    const response = await axios.get(`${process.env.getOrderDetailForRefund}?order_id=${PostData.order_id}&orderdetail=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function subDomainCheckFunction(PostData = {}, token) {
  const headers = {
    Accept: "application/json",
  };

  try {
    const response = await axios.post(process.env.subDomainCheck, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//legend page content api
async function getLegendPageFunction(slug = "us") {
  const headers = {
    Accept: "application/json",
  };
  try {
    const response = await axios.get(
      `${process.env.legendPageUrl}?slug=${slug}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// update profileUpdate
async function profileUpdate(PostData = {}, token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.post(process.env.profileUpdate, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// GetCommissionsApproved Order
async function getAllTranaction(token, range = "", PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getAllTranaction}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ""}&till_date=${PostData?.til_date ? PostData.til_date : ""}&page=${PostData?.page ? PostData?.page : "1"}&alltransaction=${timestamp}`, { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// set default card

async function defaultCard(token, Payload = {}) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.post(process.env.defaultCard, Payload, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// Api for get downline user an d used in Dashboard

async function getNewDownlineUsers(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getNewDownlineUsers}/?newdownline=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getDownlineUserAddress
async function getDownlineUserAddress(token, orderID) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getDownlineUserAddress}?user_id=${orderID}&downlineadd=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getKaireCashTranaction
async function getKaireCashTranaction(token, range, PostData = {}) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(
      `${process.env.getKaireCashTranaction}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ""
      }&till_date=${PostData?.til_date ? PostData.til_date : ""}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// Api for get dashboard news an d used in Dashboard

async function getDashboardNews(token) {
  const headers = {
    Authorization: `${token}`,
  };

  try {
    const response = await axios.get(`${process.env.getDashboardNews}`, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// update profileUpdate

async function profileImageUpload(PostData, token) {
  const headers = {
    Authorization: `${token}`,
    // 'Content-Disposition': 'attachment; filename="My Report.png"'
    "Content-Type": "multipart/related; boundary=xy1z",
    // "Content- Disposition": ` form - data; name = "aFile"; filename=${ PostData.file12.name } `
  };
  const formData = new FormData();

  // Update the formData object
  formData.append("file", PostData);
  try {
    const response = await axios.post(
      process.env.profileImageUpload,
      formData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// Api for get getDocuments

async function getDocuments(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token} `,
  };

  try {
    const response = await axios.get(`${process.env.getDocuments}?document=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// Api for get getDocuments

async function downloadDoc(url) {
  const timestamp = Date.now();
  const headers = { Authorization: `` };

  try {
    const response = await axios.get(`${url}/?documents=${timestamp}`, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}

//multi Add to cart api

async function multiProductAddToCart(token, formData) {
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      `${process.env.multiProductAddToCart} `,
      formData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
async function getSavedCards(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getSavedCards}?savedCards=${timestamp} `, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}

//Card api section *********************************************************/

async function saveNewCard(token, payload) {
  // card saved api
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(`${process.env.saveNewCard} `, payload, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function deleteCard(token, payload) {
  // card delete api
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(`${process.env.deleteCard} `, payload, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function autoshipCardUpdate(token, payload) {
  // card delete api
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  return axios
    .post(`${process.env.autoshipCardUpdate} `, payload, { headers })
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

//coupon list api

async function CouponListFunction() {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };

  return axios
    .get(`${process.env.couponListApi} `, { headers })
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

//send Coupon api
async function SendCouponFunction(payload) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };

  return axios
    .post(`${process.env.sendCouponApi} `, payload, { headers })
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

//shipping amount apis ***********************************************/

async function availableShippingModules(token, payload) {
  let check = token ? { Authorization: token } : {};

  const headers = {
    ...check,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      `${process.env.availableShippingModules} `,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

async function calculateShipping(token, payload) {
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      `${process.env.calculateShipping} `,
      payload,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

//get upcoming autoship data

async function getUpcommingAutoship(token) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getUpcommingAutoship}/?upcoming=${timestamp} `, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}

//get kiare user data
async function getKaireUsers(token) {
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getKaireUsers} `, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//get DNA-reports
async function DNA_reports() {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
  };
  try {
    const response = await axios.get(`${process.env.DNA_reports}?rep=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getKairePageDetails(token) {
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getKairePageDetails} `, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}


async function getActiveSmartships(token, pageNumber) {
  const timestamp = Date.now();
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getActiveSmartships}?page=${pageNumber}&pageSize=10&active=${timestamp}`, { headers, });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function kitListingRequest(request = "GET", postData = null) {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    let response = await axios.get(`${process.env.kitListing}?order_id = 3`, {
      headers,
    });
    if (request === "POST") {
      response = await axios.post(`${process.env.kitListing} `, postData, {
        headers,
      });
    }
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for consent form
async function consentformApi(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.consentformApi, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for becomeaffiliate
async function becomeAffiliateApi(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(
      process.env.becomeAffiliateApi,
      postData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for search bar code

async function searchbarcode(token, postData) {
  const headers = {
    Authorization: `${token} `,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.searchbarcode, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for newsletter
async function newsletter(postData) {
  const headers = { "Content-Type": "application/json" };
  try {
    const response = await axios.post(process.env.newsletter, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
async function permalinkAPI(unique_id) {
  const timestamp = Date.now();
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(`${process.env.permalinkAPI}?unique_id=${unique_id}&permalink=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for calculate tax shipping
async function calculateTaxAmount(postData) {
  const headers = { "Content-Type": "application/json" };
  try {
    const response = await axios.post(
      process.env.calculateTaxAmount,
      postData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for consent other form
async function consentOtherApi(postData) {
  const headers = { "Content-Type": "application/json" };
  try {
    const response = await axios.post(process.env.consentOtherApi, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

// reviews api //
async function snipReviewsApi(postData) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("Token"),
  };
  try {
    const response = await axios.post(process.env.snipReviewsApi, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

//checkout free kit barcode product

async function createOrderBarCode(postData) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("Token"),
  };
  try {
    const response = await axios.post(process.env.create_bundle_order, postData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete lead data

async function leadDataDelete(PostData, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.leadDataDelete, { id: PostData }, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function showToPprofessional(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(
      process.env.show_to_professional,
      postData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

async function payoutRegister(postData, action = "get") {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    let response = "";
    if (action === "get") {
      response = await axios.get(`${process.env.payoutRegister}/?payout=${timestamp}`, { headers });
    } else {
      response = await axios.post(process.env.payoutRegister, postData, {
        headers,
      });
    }
    return response;
  } catch (error) {
    return error.response;
  }
}
async function payoutAddEwalletBalance(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(
      process.env.payoutAddEwalletBalance,
      postData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

async function unsubscribedEmail({ req, ...payload }) {
  let URL = "";
  let headers = {
    "Content-Type": "application/json",
  };
  if (req === "get") {
    URL = await axios.get(
      `${process.env.unsubscribedEmail}?email=${payload?.email}`,
      { headers }
    );
  } else {
    URL = await axios.post(process.env.unsubscribedEmail, payload, { headers });
  }
  try {
    const response = URL;
    return response;
  } catch (error) {
    return error.response;
  }
}

async function changeSubscriptionDate({ req, ...payload }) {
  const timestamp = Date.now();
  let headers = { "Content-Type": "application/json" };

  try {
    const response = await axios.get(`${process.env.changeSubscriptionDate}?public_order_id=${payload?.order_id}&date=${timestamp} `, { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

async function checkUserConsent(postData) {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.checkconsent, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function socialImageContent() {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(process.env.socialContent, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function WellnessReport(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {
    const response = await axios.post(process.env.showWellnessReport, postData, {
      headers,
    });
    return response;
  }
  catch (error) {
    return error.response;
  }
}

async function DownLineCustomerReport() {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {
    const response = await axios.get(`${process.env.downlineCustomersReports}/?customerreports=${timestamp}`, { headers });
    return response
  }
  catch (error) {
    return error.response;
  }
};

async function usersReportApi(barcode) {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {
    const response = await axios.get(`${process.env.usersReportApi}/?usersReportApi=${timestamp}&barcode=${(barcode?.barcode)}`, { headers });
    return response
  }
  catch (error) {
    return error.response;
  }
};



async function getSurveyInfo() {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {
    const response = await axios.get(`${process.env.getSurvey_Info}`, { headers });
    return response;
  }
  catch (error) {
    return error.response;
  }
}


async function kitSurveyApi() {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {
    const response = await axios.get(`${process.env.kitSurveyApi}`, { headers });
    return response;
  }
  catch (error) {
    return error.response;
  }
}

async function privacyPolicy(formData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {

    const response = await axios.post(process.env.privacy_Policy, formData, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}

async function surveyResult(formData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {

    const response = await axios.post(process.env.surveyAnswer, formData, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}

async function saveSurveyResult(formData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {

    const response = await axios.post(process.env.saveSurveyResult, formData, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}

// async function getSaveSurveyResult() {
//   const headers = {
//     Authorization: localStorage.getItem("Token"),
//     "Content-Type": "application/json",
//   }
//   try {

//     const response = await axios.get(`${process.env.SurveyResultGet}`, { headers });
//     return response
//   }
//   catch (error) {
//     return error.response
//   }
// }

async function getSaveSurveyResult() {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  }
  try {
    const response = await axios.get(`${process.env.getSaveSurveyResult}/?savesurvey=${timestamp}`, { headers });
    return response;
  }
  catch (error) {
    return error.response;
  }
}

async function catagoriesBlog(itemsPerPage) {
  const headers = {
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };
  try {

    // const response = await axios.get(process.env.blogCategories);
    const response = await axios.get(`${process.env.blogCategories}?per_page=${itemsPerPage}`, { headers });

    return response
  }
  catch (error) {
    return error.response
  }
}

async function MainBlogApi(currentPage, itemsPerPage) {
  const headers = {
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };
  try {
    const response = await axios.get(`${process.env.blogMain}?page=${currentPage}&per_page=${itemsPerPage}`, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}
async function MainBlogCategoryApi(currentPage, itemsPerPage, categories) {
  const headers = {
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };
  try {
    const response = await axios.get(`${process.env.blogMain}?page=${currentPage}&per_page=${itemsPerPage}&category_slug=${categories}`, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}
async function BlogMediaApi(ID) {
  const headers = {
    Accept: "application/json",
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };

  try {
    const response = await axios.get(`${process.env.blogMedia}/${ID}`, { headers });

    return response
  }
  catch (error) {
    return error.response
  }
}
async function singleBlogApi(ID) {
  const headers = {
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };
  try {
    // const response = await axios.get(`${process.env.blogMain}/${ID}?per_page=1`, { headers });
    const response = await axios.get(`${process.env.blogMain}/?slug=${ID}`, { headers });

    return response
  }
  catch (error) {
    return error.response
  }
}
async function authorDetailApi(ID) {
  const headers = {
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };
  try {

    // const response = await axios.get(`${process.env.authorDetail}/${ID}`);

    const response = await axios.get(`${'https://blog.snipnutrition.net/wp-json/wp/v2/post_author'}/${ID}`, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}


async function GetPersonalLandingPage(referral_code) {
  const headers = {
    Accept: "application/json",
  };

  try {
    const response = await axios.get(`${process.env.getPersonalLandingPage}?referral_code=${encodeURIComponent(referral_code?.referral_code)}`, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

async function PersonalizedLandingPage(formDataArray) {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
  }

  const responses = [];
  for (const formData of formDataArray) {
    const formDataObject = formData
    const formDataToSend = new FormData();
    formDataToSend.append("heading", formDataObject?.textContent?.heading);

    formDataToSend.append("video_url", formDataObject?.textContent?.video_url);
    formDataToSend.append("content", formDataObject?.textContent?.content);
    formDataToSend.append("page", formDataObject?.textContent?.page);
    formDataToSend.append("image", formDataObject?.images?.image);
    formDataToSend.append("video_thumbnail", formDataObject?.images?.Video_thumbnail)


    try {
      const response = await axios.post(`${process.env.LandingPage}?datapost=${timestamp}`, formDataToSend, { headers });
      responses.push(response);
      return response;
    }
    catch (error) {
      responses.push(error.response);
      return error.response;

    }
  }
};


async function PersonalizedLandingPageGet() {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
  }
  try {
    const response = await axios.get(`${process.env.LandingPage}?data=${timestamp}`, { headers });
    return response;
  }
  catch (error) {
    return error.response;

  }
};

async function LandingPagePatchRequest(formDataArray) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
  }

  const responses = [];
  for (const formData of formDataArray) {
    const formDataObject = formData
    const formDataToSend = new FormData();
    formDataToSend.append("heading", formDataObject?.textContent?.heading);
    formDataToSend.append("video_url", formDataObject?.textContent?.video_url);
    formDataToSend.append("content", formDataObject?.textContent?.content);
    formDataToSend.append("page", formDataObject?.textContent?.page);

    if (formDataObject?.images?.image !== null) {
      formDataToSend.append("image", formDataObject?.images?.image);
    }
    if (formDataObject?.images?.Video_thumbnail !== null) {
      formDataToSend.append("video_thumbnail", formDataObject?.images?.Video_thumbnail);
    }


    try {
      const response = await axios.patch(process.env.LandingPage, formDataToSend, { headers });
      responses.push(response);
      return response;
    }
    catch (error) {
      responses.push(error.response);
      return error.response;

    }
  }
};

async function SearchData(currentPage, itemsPerPage, searchItem) {
  const headers = {
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };
  try {
    const response = await axios.get(`${process.env.blogMain}?page=${currentPage}&per_page=${itemsPerPage}&search=${searchItem}`, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}

async function SearchCatagoryData(currentPage, itemsPerPage, categories, searchItem) {
  const headers = {
    Authorization: "Basic YWRtaW46SXB6cU85R0lBVGoxMUVGSU83SW9pQ1d4"
  };
  try {
    const response = await axios.get(`${process.env.blogMain}?page=${currentPage}&per_page=${itemsPerPage}&category_slug=${categories}&search=${searchItem}`, { headers });
    return response
  }
  catch (error) {
    return error.response
  }
}

async function lacorePayout(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.saveLacorePayout, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function reports_generate() {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(`${process.env.reports_generate}?gen=${timestamp}`, { headers, });
    return response;
  }
  catch (error) {
    return error.response;
  }
};

async function switchOrderApi(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(process.env.switchOrderApi, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function oneTimeOrderApi() {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(`${process.env.oneTimeOrderApi}?gen=${timestamp}`, { headers, });
    return response;
  }
  catch (error) {
    return error.response;
  }
};

async function bulkKitReportAPI(postData) {
  const timestamp = Date.now();
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(`${process.env.bulkKitReportAPI}?filter=${postData?.filter}&search=${postData?.search}&page=${postData?.page}&pageSize=${postData?.pageSize}&sortby=${postData?.sortby}&gen=${timestamp}`, { headers, });
    return response;
  }
  catch (error) {
    return error.response;
  }
};

async function contactUsFormAPI(PostData = {}) {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  try {
    const response = await axios.post(process.env.contactUsAPI, PostData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
async function CreateOrderInstant(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
  };
  try {
    const response = await axios.post(process.env.CreateOrderInstant, postData, {
      headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

const api = {
  reports_generate,
  checkUserConsent,
  PersonalizedLandingPageGet,
  unsubscribedEmail,
  changeSubscriptionDate,
  signUp,
  getBanners,
  loginUser,
  downloadAllReports,
  LandingPagePatchRequest,
  newsletter,
  addToCart,
  logoutApi,
  getAllCartProduct,
  updateProductQty,
  deleteProductFromAddToCart,
  manageAddress,
  saveAddress,
  deleteAddress,
  getProfilePageData,
  changeUserPassword,
  addToWishlist,
  becomeAffiliateApi,
  getAllWishListProduct,
  calculateTaxAmount,
  deleteProductFromwishlist,
  getAllProduct,
  getProductByCategories,
  getProductByProductid,
  getProductByVariantid,
  createOrder,
  verifycoupon,
  getAllCategory,
  updateCart,
  invitationLink,
  affiliate_lookup,
  getUserOrder,
  getAddressDetails,
  GetOrderDetail,
  permalinkAPI,
  reorderProducts,
  getAddressList,
  autoshipOrderHistory,
  defaultCard,
  autoshipOrderById,
  autoshipUpdate,
  autoshipDelete,
  autoshipSkip,
  updateAddress,
  EditSmartshipData,
  getUserNotifications,
  autoshipProductDelete,
  autoshipProductUpateproduct,
  addressUpdate,
  resetPassword,
  refundOrder,
  cancelOrder,
  getBundleProduct,
  createUpsellOrder,
  getDownlineUsers,
  redeemKaireCash,
  getRefundHistory,
  getCommissionReport,
  getCommissionsFilter,
  getRefundReport,
  getRefundReportFilter,
  getMyProfileDetails,
  getDashboardCommissions,
  GetCommissionsApproved,
  LoginCheck,
  getOrderDetailForRefund,
  profileUpdate,
  getAllTranaction,
  getNewDownlineUsers,
  getDownlineUserAddress,
  getKaireCashTranaction,
  getDashboardNews,
  filterProduct,
  LoginCheckByurl,
  leadDataDelete,
  profileImageUpload,
  getDocuments,
  downloadDoc,
  getHomepageContent,
  multiProductAddToCart,
  upComingOrders,
  saveCustomerReview,
  getSavedCards,
  saveNewCard,
  deleteCard,
  availableShippingModules,
  calculateShipping,
  autoshipCardUpdate,
  getUpcommingAutoship,
  getKaireUsers,
  getKairePageDetails,
  getActiveSmartships,
  MenuSectionOption,
  subDomainCheckFunction,
  passwordResetConfirm,
  getOrderPQVandGV,
  getLegendPageFunction,
  CouponListFunction,
  SendCouponFunction,
  clickedapi,
  supportDeskPost,
  searchbarcode,
  CheckUserEmail,
  getdashboardpopup,
  DNA_reports,
  kitListingRequest,
  consentformApi,
  consentOtherApi,
  kitSurveyApi,
  _planServices,
  freeKitProduct,
  createOrderBarCode,
  showToPprofessional,
  payoutRegister,
  payoutAddEwalletBalance,
  excludeCustomerApi,
  snipReviewsApi,
  dnaReportsapi,
  socialImageContent,
  WellnessReport,
  DownLineCustomerReport,
  getSurveyInfo,
  privacyPolicy,
  surveyResult,
  getPaginationUsersByDate,
  catagoriesBlog,
  MainBlogApi,
  BlogMediaApi,
  PersonalizedLandingPage,
  GetPersonalLandingPage,
  SearchData,
  MainBlogCategoryApi,
  singleBlogApi,
  authorDetailApi,
  SearchCatagoryData,
  getPaginationUsers,
  saveSurveyResult,
  getSaveSurveyResult,
  lacorePayout,
  usersReportApi,
  switchOrderApi,
  oneTimeOrderApi,
  bulkKitReportAPI,
  contactUsFormAPI,
  CreateOrderInstant
};

export default api;